export default [
  {
    title: 'Accueil',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Personnalisation',
    icon: 'Edit2Icon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      {
        title: 'Tailles',
        route: 'sizes',
        icon: 'BarChartIcon',
      },
      {
        title: 'Couleurs',
        route: 'colors',
        icon: 'Edit2Icon',
      },
      {
        title: 'Textures',
        route: 'textures',
        icon: 'DropletIcon',
      },
      {
        title: 'Styles de texture',
        route: 'texture-styles',
        icon: 'ImageIcon',
      },
      {
        title: 'Teintes',
        route: 'tints',
        icon: 'ApertureIcon',
      },
      {
        title: 'Catégories de teinte',
        route: 'tint-categories',
        icon: 'BookOpenIcon',
      },
      {
        title: 'Branches',
        route: 'branches',
        icon: 'CheckIcon',
      },
      {
        title: 'Types de verres',
        route: 'glass-types',
        icon: 'CircleIcon',
      },
      {
        title: 'Options de verres',
        route: 'glass-options',
        icon: 'ListIcon',
      },
      {
        title: 'Affinements',
        route: 'refinements',
        icon: 'LayersIcon',
      },
      {
        title: 'Traitements',
        route: 'processings',
        icon: 'SettingsIcon',
      },
    ],
  },
  {
    title: 'Lunettes / Accessoires',
    route: 'glasses',
    icon: 'BookOpenIcon',
  },
  
  {
    title: 'Codes promo',
    route: 'promotions',
    icon: 'PercentIcon',
  },
  
  {
    title: 'Commandes',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Utilisateurs',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Points de vente',
    route: 'stockists',
    icon: 'MapPinIcon',
  },
  {
    title: 'Avis clients',
    route: 'ratings',
    icon: 'StarIcon',
  },
  {
    title: 'Langues',
    route: 'languages',
    icon: 'GlobeIcon',
  },
  {
    title: 'Règlages généraux',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'Beta',
    route: 'betas',
    icon: 'UsersIcon',
  },
  {
    title: 'Community Picks',
    route: 'community-picks',
    icon: 'Edit2Icon',
  },
  {
    title: 'Comptes B2B / Marques',
    route: 'users-b2b',
    icon: 'UsersIcon',
  },
  {
    title: 'Comptes créateurs',
    route: 'users-creator',
    icon: 'ZapIcon',
  },
  {
    title: 'Renders',
    route: 'renders',
    icon: 'ImageIcon',
  },
  {
    title: 'Suggestions de modifications',
    route: 'edit-suggests',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'Paiements',
    route: 'payouts',
    icon: 'CreditCardIcon',
  },
  {
    title: 'Modifications profils',
    route: 'profile-suggests',
    icon: 'UserIcon',
  },

]
